import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { mapBlogs } from "../../lib/utility";

import Layout from "../../components/Layout";
import Header from "../../components/Header";
import HeroLarge from "../../components/HeroLarge";
import IntroBlock from "../../components/IntroBlock";
import Types from "../../components/Types";
import BgImage from "../../components/BgImage";
import BlogRoll from "../../components/BlogRoll";
import FindLO from "../../components/FindLO";

import styles from "./styles.module.less";

// Home loan types icons
// active
import Icons_Jumbo_active from "../../images/typesImg/Icons_Jumbo_active.png";
import Icons_USDA_active from "../../images/typesImg/Icons_USDA_active.png";
import Icons_Reverse_active from "../../images/typesImg/Icons_ReverseMortgage_active.png";
// inactive
import Icons_Jumbo_inactive from "../../images/typesImg/Icons_Jumbo_inactive.png";
import Icons_USDA_inactive from "../../images/typesImg/Icons_USDA_inactive.png";
import Icons_Reverse_inactive from "../../images/typesImg/Icons_ReverseMortgage_inactive.png";

import { specialtyLoanIcons } from "../../lib/gtm";

// bgimages
import cinemaImage from "./images/bground.jpg";
import hero from "./images/hero.jpg";

const SpecialtyLoans = ({
    data,
    object = [0, 1, 2],
    images = [Icons_Jumbo_active, Icons_USDA_active, Icons_Reverse_active],
    blueImages = [
        Icons_Jumbo_inactive,
        Icons_USDA_inactive,
        Icons_Reverse_inactive,
    ],

    captions = ["Jumbo Loans", "USDA Loans", "Reverse Mortgage"],

    infos = [
        "Jumbo loans are a special financing option for loan amounts exceeding the FHFA’s conventional conforming loan limit of $510,400 ($765,600 for AK and HI). Perfect for people looking to purchase their dream home.",
        "USDA home loans, also known as USDA Rural Development Guaranteed Housing Loans, are mortgage loans offered to property owners within specified rural areas of the United States.",
        "A reverse mortgage allows you to convert a portion of the equity in your home into cash. Meaning, the equity you’ve built up over the years of making mortgage payments can now be paid back to you.",
    ],
    links = ["/jumbo-loan", "/usda-home-loan", "/reverse-mortgage"],
    alt = [
        "Jumbo Loan Icon - Specialty Loans | Bay Equity Home Loans",
        "USDA Loan Icon - Specialty Loans | Bay Equity Home Loans",
        "Reverse Mortgage Icon - Specialty Loans | Bay Equity Home Loans",
    ],
}) => {
    const intro = "What are specialty loans?";
    const par = (
        <>
            Homes aren’t “one size fits all.” Neither are loans. But through a
            series of specialty loans such as a USDA home mortgage or a jumbo
            loan, homebuyers can find a mortgage program that works perfectly
            for their specific situation.
            <br />
            <br />
            If you find yourself in a unique spot,{" "}
            <a className={styles.parOrange} href="/find-your-loan-officer">
                reach out to one of our loan officers
            </a>{" "}
            to see if a specialty loan might be a better fit for your
            home-buying needs. We’re happy to work with you to find a program
            that’s just right.
        </>
    );

    const { corpBlogs } = data;
    const { edges: corpBlobPosts = [] } = corpBlogs || {};
    const corporateBlogs = mapBlogs(corpBlobPosts);

    const props = {
        object,
        images,
        blueImages,
        links,
        captions,
        infos,
        title: <span>Know the types of specialty&nbsp;loans</span>,
        gtm: specialtyLoanIcons,
        alt,
    };

    const header = <Header active={["current-homeowner", "specialty-loans"]} />;

    return (
        <Layout hasHero={true} header={header}>
            <Helmet>
                <title>
                    Specialty Loans - Home Loans | Bay Equity Home Loans
                </title>
                <meta
                    name="description"
                    content="Specialty loans are creative mortgage solutions for homebuyers that need unique loan programs for their unique situations. Not sure if you need a specialty loan? Contact us today."
                />
            </Helmet>
            <HeroLarge customPhoto={hero} />
            <IntroBlock header={intro} par={par} />
            <Types {...props} />

            <BgImage bg={cinemaImage} />
            <FindLO />
            {corporateBlogs.length >= 3 && (
                <BlogRoll
                    corporateBlogs={corporateBlogs}
                    blogs={corporateBlogs}
                    showBlogs={true}
                    word="our"
                    url="specialty-loans"
                />
            )}
        </Layout>
    );
};
export default SpecialtyLoans;

export const pageQuery = graphql`
    query SpecialtyLoansPageQuery {
        corpBlogs: allBlog(
            sort: { fields: [date], order: DESC }
            filter: {
                type: { eq: "corporate" }
                tags: { in: ["Specialty Loans"] }
            }
        ) {
            edges {
                node {
                    id
                    html
                    slug
                    title
                    date
                    feature
                    categories
                    image
                    author {
                        slug
                        profile {
                            name
                        }
                        gallery {
                            photo
                        }
                    }
                    date
                    feature
                }
            }
        }
    }
`;
