import React from "react";

import styles from "./styles.module.less";

const BgImage = ({ bg }) => {
    const bgStyle = {
        backgroundImage: `url(${bg})`,
    };
    return <section className={styles.BgImage} style={bgStyle} />;
};

export default BgImage;
